@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: 2, 63, 136;
    /* #023F88 */
    --secondary: 243, 251, 255;
    /* #F3FBFF */
    --muted: 128, 128, 128;
    /* #808080 */
    --body: 32, 32, 32;
    /* #202020 */
  }

  @font-face {
    font-family: 'Figtree';
    src: url('../assets/fonts/Figtree-Light.woff2') format('woff2'),
      url('../assets/fonts/Figtree-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Figtree';
    src: url('../assets/fonts/Figtree-Regular.woff2') format('woff2'),
      url('../assets/fonts/Figtree-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Figtree';
    src: url('../assets/fonts/Figtree-Medium.woff2') format('woff2'),
      url('../assets/fonts/Figtree-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Figtree';
    src: url('../assets/fonts/Figtree-SemiBold.woff2') format('woff2'),
      url('../assets/fonts/Figtree-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Figtree';
    src: url('../assets/fonts/Figtree-Bold.woff2') format('woff2'),
      url('../assets/fonts/Figtree-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
}

@layer components {
  .form-control {
    @apply bg-white font-medium border border-[#CFD6DD] text-sm block w-full px-4 py-3.5 leading-[1.458] focus:border-primary focus:outline-none placeholder-[#BABABA] rounded-md;
  }
}

.flag__dropdown{
  @apply bg-white;
}
.flag__selected-flag{
 @apply !w-[121px] !border-0;
}
.flag__arrow::before{
 background: url(../assets/images/arow-down.png);
 @apply w-[11px] h-[6px] bg-no-repeat text-[0px] bg-center;
}